.Muaythai__navbar {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;
}
.Muaythai__navbar-links_logo img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 45vw;
    cursor: pointer;
}

.Muaythai__navbar-links_container{
  display: flex;
  flex-direction: row;  
  margin-top: 5%;
  justify-content: space-between;
  align-items: center;

}
.Muaythai__navbar-links_border{
    border-bottom: 1px solid black;
    margin-top: 5%;

}


  .Muaythai__navbar-links_container li{
    list-style-type: none;
    color: #FFB000; 
    font-family: Montagu Slab;
    font-weight: 700;

    font-size: 1.3vw;
    white-space: nowrap;
  }

  .Muaythai__navbar-menu {
    margin-left: 0rem;
    display: none;
    position: relative;
  }

.Muaythai__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {

    .Muaythai__navbar {
        display: block;
        justify-content: space-between;
        align-items: center;
    }


    

}
@media screen and (max-width: 700px) {
    .Muaythai__navbar {
        display: block;
        justify-content: space-between;
        align-items: center;

    }


    
}

@media screen and (max-width: 550px) {
    .Muaythai__navbar {
        display: block;
        justify-content: space-between;
        align-items: center;

        margin-left: 20px;
        margin-right: 20px;
    }
    .Muaythai__navbar-links_container li{
        color: #FFB000; 
        font-family: Montagu Slab;
        font-weight: 700;
    
        font-size: 1.8vw;
        white-space: pre-line;
      }

}
