.about-main{
    margin-left: 15%;
    margin-right: 15%;


}
.header {
    display: flex;
    align-items: center;
    
}

.about {
    font-family: Montagu Slab;
    font-weight: 700;
    font-size: 3.5vw;
    color: #FFB000;
    text-align:left;
}


.description-text {
    text-align: left;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 167%;


}

.photo img {
    display: block;
    float: right;
    max-width: 25vw;
   
}




@media screen and (max-width: 550px){
    .about-main{
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 5%;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }


    .description-text{
        margin-top: 0;
        text-align: left;
        font-size: 3.5vw;
        width: 90vw;

    }
    .about{
        font-size: 5.5vw;
        width: 350px;
    }
    .photo img{
        max-width: 300px;
        margin-top: 3%;
        display: block;
    }
    .photo{
        display: block;
        align-items: center;
        margin: auto;

    }


}
