@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@100;200;300;400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-family: 'Montagu Slab', 'Montserrat', sans-serif;

  --color-bg: #FFFFFF;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #FFB000;
  --color-subtext: #000000;
}
