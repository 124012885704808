.faq-main{
    margin-right: 15%;
      margin-left: 15%;
      margin-top: 3%;
    }
    
.faq-title{
        font-family: Montagu Slab;
        font-weight: 700;
        font-size: 3.5vw;
        color: #FFB000;
    }
.faq-body{
    margin-top: 3%;
}



  
 
  .accordion-item {
    margin-bottom: 2.5%;
  }
  

  .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 176, 0, 0.50);
    padding: 1.1vw;
    cursor: pointer;
    font-size: 2.5vw;
    font-family: Montserrat;
    font-weight: 400;
  }
  

  .accordion-content {
    color: #000;
    font-family: Montserrat;
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5vw;
    margin-top: 1%;
  }

  @media screen and (max-width: 550px) {
    .faq-main{
        margin-right: 20px;
        margin-left: 20px;
    }
    .faq-title{
        font-size: 5.5vw;
    }
    .accordion-content{
        font-size: 2vw;
    }
    .accordion-item {
        margin-bottom: 3.5%;
      }

}