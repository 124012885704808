.header-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center elements horizontally */
  }

.MuaythaiHeader{
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    display: inline-block;
}

.Muaythai-words{
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-top: 3%;
    margin-left: 15%;
    margin-right: 15%;
}


.Muaythai-button button {
border-radius: 100px;
background: rgba(255, 176, 0, 0.50);
color: #000;
width: 17.5vw;
height: 6vw;
flex-shrink: 0;
border: none;
font-family: Montserrat;
font-weight: 700;
margin: 30px 50px;
font-size: 1.5vw;
cursor: pointer;
}


@media screen and (max-width: 1050px){

    .Muaythai-words{
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;

    }

    .Muaythai-button button {
        width: 135.565px;
        height: 50px;
        flex-shrink: 0;
        margin: 30px 30px;
        }
}


@media screen and (max-width: 700px){
    .Muaythai-button button {
        width: 125.565px;
        height: 50px;
        flex-shrink: 0;
        margin: 20px 20px;
        }

        .Muaythai-words{
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;

        }

}

@media screen and (max-width: 550px){
    .Muaythai-button button {
        width: 150px;
        height: 35px;
        flex-shrink: 0;
        margin: 10px 10px;
        font-size: 2.5vw;
        }  
        .Muaythai-words{
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            margin-left: 20px;
            margin-right: 20px; 
        }   
    .Muaythai-words{
        font-size: 3.65vw;
    }
}